import useHasura from '@/hooks/useHasura'
import { Promobar } from '@/ts/Promobar'

import { GET_PROMOBAR } from '../queries/Promobar'

function usePromobar() {
  const { data, ...rest } = useHasura<Data>('promobar', GET_PROMOBAR)

  return { promobar: data, ...rest }
}

interface Data {
  promobar: Promobar[]
}

export default usePromobar
