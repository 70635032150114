const ROUTES: Route[] = [
  {
    name: 'creators',
    url: '/creators',
    submenu: false,
    icon: false,
  },
  {
    name: 'new',
    url: '/products/new',
    submenu: true,
    icon: true,
  },
  {
    name: 'bestsellers',
    url: '/products/bestsellers',
    submenu: true,
    icon: true,
  },
  {
    name: 'sale',
    url: '/products/sale',
    submenu: true,
    icon: true,
  },
  {
    name: 'digital',
    url: '/products/digital',
    submenu: true,
    icon: true,
  },
  {
    name: 'fansi_collection',
    url: '/products/fansi-collections',
    submenu: true,
    icon: true,
  },
  {
    name: 'fansi_class',
    url: '/products/fansi-class',
    submenu: true,
    icon: true,
  },
  {
    name: 'fansi_video',
    url: '/products/fansi-video',
    submenu: true,
    icon: true,
  },
  {
    name: 'fansi_experience',
    url: '/products/fansi-experience',
    submenu: false,
    icon: false,
  },
]

type Route = {
  name:
    | 'digital'
    | 'fansi_class'
    | 'fansi_collection'
    | 'fansi_experience'
    | 'fansi_video'
    | 'creators'
    | 'bestsellers'
    | 'new'
    | 'sale'
  url: string
  submenu: boolean
  icon: boolean
}

export default ROUTES
