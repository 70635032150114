enum ProductType {
  Digital = 'Product::Digital',
  FansiClass = 'Product::FansiClass',
  FansiCollection = 'Product::FansiCollection',
  FansiVideo = 'Product::FansiVideo',
  FansiExperience = 'Product::FansiExperience',
}

export const ProductTypeAsTitleMap: Record<ProductType, string> = {
  'Product::Digital': 'Produkty Cyfrowe',
  'Product::FansiClass': 'fansi Class',
  'Product::FansiCollection': 'fansi Kolekcje',
  'Product::FansiExperience': 'fansi Experience',
  'Product::FansiVideo': 'fansi Video',
}

export default ProductType
