import { gql } from 'graphql-request'

const GET_FILTERS_CATEGORIES = gql`
  query getFilters($for_creators: Boolean!, $type: String) {
    product_categories(
      where: {
        _and: {
          products: {
            product: {
              status: { _eq: "active" }
              type: { _eq: $type, _neq: "Product::FansiForBrands" }
            }
          }
        }
      }
      order_by: { sort: asc }
    ) @skip(if: $for_creators) {
      id
      name
      icon
      sort
    }
    creator_categories(
      where: {
        _and: {
          creators: {
            account_status: { _eq: "active" }
            products: { status: { _eq: "active" }, type: { _eq: $type } }
          }
        }
      }
    ) {
      id
      name
    }
  }
`
export default GET_FILTERS_CATEGORIES
