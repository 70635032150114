import getEndpoint from '@/helpers/getEndpoint'
import API from '@/services/API'

export async function removeFromFavoriteAsLoggedIn(
  id: number,
  refetch: () => void
) {
  const endpoint = getEndpoint('FAVOURITES_REMOVE')
  const body = { product_id: id }

  const response = await API.delete(endpoint, { body })
  if (response.status === 'success') {
    await refetch()
    return response
  } else {
    throw new Error()
  }
}

export async function removeFromFavoriteAsGuest(id: number) {
  const productIds: number[] = JSON.parse(
    localStorage.getItem('favourites') ?? '[]'
  )

  const filteredProductIds = productIds.filter((element) => element !== id)
  localStorage.setItem('favourites', JSON.stringify(filteredProductIds))
}
