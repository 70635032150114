import clsx from 'clsx'

import Icon from '@/atoms/Icon'
import Link from '@/atoms/Link'

function Logo({ className }: LogoProps) {
  return (
    <Link className={clsx(className)} href="/">
      <Icon name="logo" />
      <span className="sr-only">Skip to homepage</span>
    </Link>
  )
}

interface LogoProps {
  className?: string
}

export default Logo
