import qs from 'query-string'

function stringify(object: Record<string, number[]>) {
  const queryString = qs.stringify(object, {
    arrayFormat: 'separator',
    arrayFormatSeparator: '|',
    skipEmptyString: true,
    skipNull: true,
  })

  return window.btoa(queryString)
}

export default stringify
