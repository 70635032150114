import createStore from '../../middleware'

export const useFavouritesStore = createStore({
  name: 'Favourites',
  state: {
    productIds: [] as number[],
  },
  actions: (set) => ({
    setProductIds: (productIds: number[]) =>
      set((state) => {
        state.productIds = productIds
      }),
  }),
})
