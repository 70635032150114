import dynamic from 'next/dynamic'

import useAuth from '@/api/hooks/useAuth'
import Icon from '@/atoms/Icon'
import Link from '@/atoms/Link'
import useLikedProducts from '@/graphql/hooks/useLikedProducts'
import useCartStore from '@/store/slices/cart'

const Badge = dynamic(() => import('./includes/Badge'), { ssr: false })

function IconGroup() {
  const { data: user } = useAuth('user')
  const { count: likedCount } = useLikedProducts()

  const cart = useCartStore((state) => state.products)
  const inCartCount = cart.length

  return (
    <div className="flex items-center space-x-4 text-white sm:space-x-6">
      {user && (
        <a className="w-5 lg:hidden" href="/customer/fansi-experience">
          <Icon name="rocket" className="text-white" />
        </a>
      )}
      <Link
        className="relative w-5 h-5 text-white hover:text-white/60"
        href="/favourites"
      >
        <Icon name="heart" />
        <Badge count={likedCount} />
      </Link>
      <Link
        className="relative w-5 h-5 text-white hover:text-white/60"
        href="/cart"
      >
        <Icon name="shopping_cart" />
        <Badge count={inCartCount} />
      </Link>
    </div>
  )
}

export default IconGroup
