import clsx from 'clsx'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'

import Field from '@/atoms/Field'
import Icon from '@/atoms/Icon'

function Search({ className }: SearchProps) {
  const { t } = useTranslation('global')

  const { handleSubmit, register, setValue } = useForm<{
    search: string | undefined
  }>()
  const {
    push,
    query: { q },
  } = useRouter()

  useEffect(() => {
    setValue('search', q?.toString())
  }, [q])

  const onSubmit = handleSubmit(
    (data) => data.search && push(`/search?q=${data.search.trim()}`)
  )

  return (
    <form
      className={clsx(
        'flex items-center justify-center w-full md:max-w-sm',
        className
      )}
      autoComplete="off"
      onSubmit={onSubmit}
    >
      <Field className="relative w-full mt-4 md:mt-0 group lg:mt-0">
        <Field.Input
          {...register('search')}
          className="!rounded-3xl w-full !border-[#108d85] "
          placeholder={t('search_placeholder')}
          style={{ paddingLeft: 20, paddingRight: 40 }}
        />
        <Icon
          onClick={onSubmit}
          className="absolute w-5 h-5 transition-all cursor-pointer top-3 right-4 text-[#108d85] "
          name="search"
        />
      </Field>
    </form>
  )
}

interface SearchProps {
  className?: string
}

export default Search
