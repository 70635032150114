import { nanoid } from 'nanoid'

import usePromobar from '@/graphql/hooks/usePromobar'

function Promobar() {
  const { promobar } = usePromobar()

  const promobarText = promobar?.promobar[0]?.promobar_text
  const promobarUrl = promobar?.promobar[0]?.promobar_url
  const visible = promobar?.promobar[0]?.promobar_enabled

  return (
    <>
      {visible === true && (
        <div className="pl-2 overflow-hidden font-semibold text-justify text-white bg-violet-200">
          {promobarUrl === '' ? (
            <div className="flex space-x-4 cursor-default w-max">
              {[...Array(25)].fill(null).map(() => (
                <PromobarContent promobarText={promobarText} key={nanoid()} />
              ))}
            </div>
          ) : (
            <a href={promobarUrl}>
              <div className="flex space-x-6 cursor-pointer w-max">
                {[...Array(25)].fill(null).map(() => (
                  <PromobarContent promobarText={promobarText} key={nanoid()} />
                ))}
              </div>
            </a>
          )}
        </div>
      )}
    </>
  )
}

function PromobarContent({ promobarText }: PromobarContentProps) {
  return <span className="my-1 text-xs max-w-max">{promobarText}</span>
}

interface PromobarContentProps {
  promobarText?: string
}

export default Promobar
