import { memo } from 'react'

import Navigation from './includes/Navigation'
import Promobar from './includes/Promobar'
import Subnavigation from './includes/Subnavigation/Subnavigation'

function Header() {
  return (
    <header>
      <Promobar />
      <Navigation />
      <Subnavigation />
    </header>
  )
}

export default memo(Header)
