import dynamic from 'next/dynamic'
import { memo } from 'react'

import Logo from '@/atoms/Logo'
import MenuToggle from '@/atoms/MenuToggle'
import useModalStore, { showModal } from '@/store/slices/modal'

import IconGroup from './includes/IconGroup'
import Search from './includes/Search'

const AuthGroup = dynamic(() => import('./includes/AuthGroup'), {
  ssr: false,
})

function Navigation() {
  const { name } = useModalStore((state) => state)
  const isVisible = name === 'mobile_menu'

  const showMenu = () => {
    window.scrollTo({ top: 0 })
    showModal('mobile_menu')
  }

  return (
    <nav className="py-3 lg:py-3 flex flex-wrap items-center justify-center md:justify-between lg:justify-start lg:flex-nowrap bg-[#85cfc3]">
      <div className="relative flex box">
        <MenuToggle onShow={showMenu} isVisible={isVisible} />
        <div className="relative flex flex-col items-center justify-between flex-1 md:space-x-4 md:mx-8 lg:mx-0 md:flex-row">
          <Logo className="text-white hover:text-violet-5 max-w-[82px] lg:max-w-[96px] mb-0.5 lg:mb-1" />
          <Search />
        </div>
        <div className="absolute flex items-center top-2 md:top-0 md:relative right-5 lg:ml-8 lg:order-last">
          <div className="hidden lg:mr-8 lg:flex lg:justify-end lg:ml-4">
            <AuthGroup />
          </div>
          <IconGroup />
        </div>
      </div>
    </nav>
  )
}

export default memo(Navigation)
