import { gql } from 'graphql-request'

const GET_COLLECTION_CREATORS = gql`
  query getCollectionCreators {
    creators(
      limit: 20
      where: {
        _and: {
          account_status: { _eq: "active" }
          deleted: { _nin: ["true"] }
          products: {
            type: { _eq: "Product::FansiCollection" }
            status: { _eq: "active" }
          }
        }
      }
    ) {
      id
      avatar: avatar_link
      nickname
      slug
    }
  }
`
export default GET_COLLECTION_CREATORS
