import { useEffect } from 'react'

import useAuth from '@/api/hooks/useAuth'
import useHasura from '@/hooks/useHasura'
import {
  addFavoriteAsGuest,
  addFavoriteAsLoggedIn,
} from '@/molecules/ProductCard/logic/addFavourite'
import {
  removeFromFavoriteAsGuest,
  removeFromFavoriteAsLoggedIn,
} from '@/molecules/ProductCard/logic/removeFromFavourites'
import { useFavouritesStore } from '@/store/slices/favourites'
import { LikedProducts } from '@/ts/LikedProducts'
import Product from '@/ts/Product'

import {
  GET_LIKED_PRODUCTS,
  GET_PRODUCTS_BY_IDS,
} from '../queries/getLikedProducts'

export function getLikedProductsFromStorage() {
  if (typeof window === 'undefined') return []
  const productIds: number[] = JSON.parse(
    localStorage?.getItem('favourites') ?? '[]'
  )
  return productIds
}

function useLikedProducts() {
  const productIds = useFavouritesStore((store) => store.productIds)
  const setProductIds = useFavouritesStore((store) => store.setProductIds)

  const { data: user, isLoading } = useAuth('user')

  const isLoggedIn = Boolean(user)

  const { data, refetch } = useHasura<Data>(
    'liked_products',
    isLoggedIn ? GET_LIKED_PRODUCTS : GET_PRODUCTS_BY_IDS,
    {
      variables: isLoggedIn
        ? { user_id: user?.id }
        : { product_ids: productIds },
    }
  )

  const count = productIds.length
  const products: Product[] =
    data?.favorites?.map((item) => item.product) ?? data?.products ?? []

  useEffect(() => {
    if (isLoggedIn) {
      setProductIds(products.map((product) => product.id))
    }
  }, [data])

  useEffect(() => {
    if (!user) {
      setProductIds(getLikedProductsFromStorage())
    }
  }, [user, isLoading])

  function toggleFavourite(id: number) {
    if (productIds?.includes(id)) {
      isLoggedIn
        ? removeFromFavoriteAsLoggedIn(id, refetch)
        : removeFromFavoriteAsGuest(id)
    } else {
      isLoggedIn ? addFavoriteAsLoggedIn(id, refetch) : addFavoriteAsGuest(id)
    }

    if (!user) setProductIds(getLikedProductsFromStorage())
  }

  return {
    productIds,
    products,
    count,
    refetch,
    toggleFavourite,
  }
}

interface Data {
  products: Product[]
  favorites: LikedProducts[]
  favorites_aggregate: { aggregate: { count: number } }
}

export default useLikedProducts
