import { gql } from 'graphql-request'

export const GET_LIKED_PRODUCTS = gql`
  query getLikedProducts($user_id: bigint, $product_ids: [bigint!]) {
    favorites(where: { user_id: { _eq: $user_id } }) {
      id
      user_id
      product_id
      product {
        id
        slug
        name
        cover: main_photo_url
        created_at
        duration: duration_of_event
        type
        status
        embedded_link
        tags
        newness
        bestseller
        creator {
          id
          slug
          nickname
          avatar: avatar_link
        }
        categories: product_categories {
          id: product_category_id
          category: product_category {
            id
            name
          }
        }
        entries: product_contest_entries {
          id
          name
          price {
            value: price_subunit
            currency: price_currency
          }
        }
        price {
          currency: price_currency
          value: price_subunit
          price_before_discount: price_before_discount_subunit
        }
      }
    }
  }
`

export const GET_PRODUCTS_BY_IDS = gql`
  query getProductsByIds($product_ids: [bigint!]) {
    products(where: { id: { _in: $product_ids } }) {
      id
      slug
      name
      cover: main_photo_url
      created_at
      duration: duration_of_event
      type
      status
      embedded_link
      tags
      newness
      bestseller
      creator {
        id
        slug
        nickname
        avatar: avatar_link
      }
      categories: product_categories {
        id: product_category_id
        category: product_category {
          id
          name
        }
      }
      entries: product_contest_entries {
        id
        name
        price {
          value: price_subunit
          currency: price_currency
        }
      }
      price {
        currency: price_currency
        value: price_subunit
        price_before_discount: price_before_discount_subunit
      }
    }
  }
`
