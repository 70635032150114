import MenuToggleCross from './includes/MenuToggleCross'
import MenuToggleGrid from './includes/MenuToggleGrid'

function MenuToggle(props: MenuToggleProps) {
  const { isVisible, onHide, onShow } = props

  return (
    <>
      <button className="absolute flex items-center justify-center z-above-all left-3 md:top-2 md:relative w-7 h-7 lg:hidden focus:outline-none">
        <MenuToggleGrid onShow={onShow} isVisible={isVisible} />
        <MenuToggleCross onHide={onHide} isVisible={isVisible} />
        <span className="sr-only">Toggle menu</span>
      </button>
    </>
  )
}

interface MenuToggleProps {
  isVisible: boolean
  onShow(): void
  onHide?(): void
}

export default MenuToggle
