import getEndpoint from '@/helpers/getEndpoint'
import API from '@/services/API'

export async function addFavoriteAsLoggedIn(id: number, refetch: () => void) {
  const endpoint = getEndpoint('FAVOURITES')
  const body = { product_id: id }

  const response = await API.post(endpoint, { body })
  if (response.status === 'success') {
    await refetch()
    return response
  } else {
    throw new Error()
  }
}

export async function addFavoriteAsGuest(id: number) {
  const productIds: number[] = JSON.parse(
    localStorage.getItem('favourites') ?? '[]'
  )

  productIds.push(id)
  localStorage.setItem('favourites', JSON.stringify(productIds))
}

export async function sendLikedProductsToAPI(ids: number[]) {
  const endpoint = getEndpoint('FAVOURITES_BATCH')
  const body = { product_ids: ids }

  const response = await API.post(endpoint, { body })
  if (response.status === 'success') {
    return response
  } else {
    throw new Error()
  }
}
