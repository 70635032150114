import GET_COLLECTION_CREATORS from '@/graphql/queries/creators/getCollectionCreators'
import GET_FILTERS_CATEGORIES from '@/graphql/queries/global/getFiltersCategories'
import useHasura from '@/hooks/useHasura'
import Creator from '@/ts/Creator'
import ProductType from '@/ts/enums/product/ProductType'
import FilterGroup from '@/ts/interfaces/global/FilterGroup'

function useStaticProductsCategories(props: UseStaticProducts) {
  const { staticFilters, staticCreators } = props
  const { withCollections = false, onlyCreators = false, productType } = props

  const config = { initialData: staticFilters }
  const variables = {
    for_creators: onlyCreators,
    type: productType,
  }

  const filtersQuery = useHasura('filters', GET_FILTERS_CATEGORIES, {
    config,
    variables,
  })

  const collectionCreatorsQuery = useHasura(
    'collection_creators',
    GET_COLLECTION_CREATORS,
    {
      config: {
        enabled: withCollections,
        initialData: staticCreators,
      },
    }
  )

  const filters = filtersQuery.data
  const creators = collectionCreatorsQuery.data?.creators

  return { filters, creators }
}

interface UseStaticProducts {
  staticCreators?: {
    creators: Creator[]
  }
  staticFilters?: FilterGroup
  withCollections?: boolean
  onlyCreators?: boolean
  productType?: ProductType
}

export default useStaticProductsCategories
