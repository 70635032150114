import clsx from 'clsx'
import { useRouter } from 'next/router'
import useTranslation from 'next-translate/useTranslation'
import { memo } from 'react'

import Icon from '@/atoms/Icon'
import Link from '@/atoms/Link'
import ROUTES from '@/constants/routes'

import CategoriesMenu, {
  NAVIGATION_PRODUCT_TYPE,
} from './includes/CategoriesMenu'

function Subnavigation() {
  const { t } = useTranslation('global')
  const { pathname } = useRouter()

  return (
    <nav className="hidden md:py-8 lg:block box md:border-b border-w border-sbtgray-200">
      <ul className="flex justify-center space-x-4 text-center">
        {ROUTES.map(({ name, url, icon, submenu }) => (
          <li
            key={name}
            className="flex flex-col items-center space-y-4 xl:space-y-5"
          >
            <Link
              href={url}
              className={clsx(
                'hover:text-violet-200 transition-colors text-xs xl:text-sm  2xl:text-base peer flex',
                {
                  'text-black/60': url !== pathname,
                  'font-medium  text-black/90': url === pathname,
                }
              )}
            >
              {t(name)}
              {icon && <Icon name="chevron" className="w-2 ml-2" />}
            </Link>
            {submenu === true && (
              <CategoriesMenu
                productType={NAVIGATION_PRODUCT_TYPE[name]}
                url={url}
              />
            )}
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default memo(Subnavigation)
