import Icon from '@/atoms/Icon'
import stringify from '@/services/Filters/functions/stringify'
import ProductType from '@/ts/enums/product/ProductType'
import useStaticProductsCategories from '@/views/products/functions/useStaticProductCategories'

function CategoriesMenu({ productType, url }: CategoriesMenuProps) {
  const { filters } = useStaticProductsCategories({
    productType: productType,
  })

  const productCategories = filters?.product_categories

  return (
    <div className="absolute flex-col items-start hidden px-6 pt-6 pb-2 space-y-2 bg-white rounded-md shadow-md peer-hover:box peer-hover:flex z-above-all hover:flex">
      <ul>
        {productCategories?.map((item) => (
          <a
            href={`${url}/?filters=${stringify({
              product_categories: [Number(item.id)],
            })}`}
            key={item.id}
          >
            <li className="flex justify-start mb-2 space-x-2 cursor-pointer text-black/70 hover:text-sbtviolet-650 hover:underline">
              <Icon name={item.icon} className="w-5" />
              <p className="text-md max-w-max">{item.name}</p>
            </li>
          </a>
        ))}
      </ul>
    </div>
  )
}

interface CategoriesMenuProps {
  productType: ProductType
  url: string
}

export const NAVIGATION_PRODUCT_TYPE = {
  fansi_class: ProductType.FansiClass,
  fansi_collection: ProductType.FansiCollection,
  digital: ProductType.Digital,
  fansi_experience: ProductType.FansiExperience,
  fansi_video: ProductType.FansiVideo,
} as Record<string, ProductType>

export default CategoriesMenu
