import { gql } from 'graphql-request'

export const GET_PROMOBAR = gql`
  query getPromobar {
    promobar: settings {
      id
      promobar_enabled
      promobar_text
      promobar_url
    }
  }
`
