import clsx from 'clsx'

import Icon from '@/atoms/Icon'

function MenuToggleGrid(props: MenuToggleGridProps) {
  const { onShow, isVisible } = props

  return (
    <Icon
      name="plain_dot_grid"
      onClick={() => onShow()}
      className={clsx('text-white absolute transition-transform duration-300', {
        'scale-0': isVisible,
        'scale-100': !isVisible,
      })}
    />
  )
}

interface MenuToggleGridProps {
  onShow(): void
  isVisible: boolean
}

export default MenuToggleGrid
