import clsx from 'clsx'

import Icon from '@/atoms/Icon'
import { hideModals } from '@/store/slices/modal'

function MenuToggleCross(props: MenuToggleCrossProps) {
  const { onHide, isVisible } = props

  const hideMenu = () => {
    hideModals()
    onHide && onHide()
  }

  return (
    <Icon
      name="cross"
      onClick={hideMenu}
      className={clsx(
        'absolute w-5 h-5 text-violet-225 transition-transform duration-300',
        {
          'scale-0': !isVisible,
          'scale-100': isVisible,
        }
      )}
    />
  )
}

interface MenuToggleCrossProps {
  onHide?(): void
  isVisible: boolean
}

export default MenuToggleCross
